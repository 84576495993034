import React from "react"
import { graphql } from "gatsby"
import BaseAbout from "../../components/layout/page/base-about"
import DonateButton from "../../components/donate/donate-button"

const HowToHelpAbout = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  } = data

  return (
    <BaseAbout title={title} html={html}>
      <div className="w-full md:w-3/4 mt-6 md:mt-8">
        <DonateButton />
        <div className="text-xs italic mt-2">
          Donations are securely collected through the PayPal website
        </div>
      </div>
    </BaseAbout>
  )
}

export default HowToHelpAbout

export const query = graphql`
  query HowToHelpQuery {
    markdownRemark(frontmatter: { name: { eq: "how-to-help" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
